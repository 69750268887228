/* eslint-disable */

__DOTENV_REQUIRE__;

module.exports = {
  env: process.env.APP_ENV,
  baseFontSizeRem: process.env.FONT_SIZE,
  apiUrl: process.env.API_URL,
  apiUrlFront: process.env.API_URL_FRONT,
  locale: 'ru',
  // Listen to errors on browsers
  browsers: {
    Chrome: 68,
    Firefox: 61,
    Safari: 10,
    Edge: 16,
    Opera: 54,
  },

  // Ports which each application listens
  ports: {
    domik: { dev: 3000, prod: 8089 },
    backoffice: { dev: 3001, prod: 8081 },
    forum: { dev: 3002, prod: 8082 },
    bild: { dev: 3003, prod: 8083 },
    websocket: { dev: 3004, prod: 8084 },
    olddomik: {dev: 3005, prod: 8085 },
    bildcontrol: { dev: 3006, prod: 8086 },
    a2: { dev: 3007, prod: 8087 },
    sandbox: { dev: 3090, prod: 8090 },
    tests: { dev: 3070, prod: 8070 }
  },

  protos: {
    domik: process.env.PROTO_DOMIK,
    backoffice: process.env.PROTO_BACKOFFICE,
    forum: process.env.PROTO_FORUM,
    sandbox: process.env.PROTO_SANDBOX,
    bild: process.env.PROTO_BILD,
    bildcontrol: process.env.PROTO_BILDCONTROL,
    a2: process.env.PROTO_A2,
    olddomik: process.env.PROTO_OLDDOMIK
  },

  domains: {
    domik: process.env.DOMAIN_DOMIK,
    backoffice: process.env.DOMAIN_BACKOFFICE,
    forum: process.env.DOMAIN_FORUM,
    sandbox: process.env.DOMAIN_SANDBOX,
    tests: process.env.DOMAIN_TESTS,
    bild: process.env.DOMAIN_BILD,
    bildcontrol: process.env.DOMAIN_BILDCONTROL,
    a2: process.env.DOMAIN_A2,
    olddomik: process.env.DOMAIN_OLDDOMIK,
    websocket: process.env.DOMAIN_WEBSOCKET,
  },

  defaultDescription: {
    domik: 'domik.ua - Новостройки Украины',
    forum: 'Forum.domik.ua - Новостройки Украины',
    bild: 'bild.ua - Новостройки Украины'
  },

  titleSuffix: {
    domik: ' — Domik.ua',
    forum: ' — Форум Domik.ua',
    bild: ' — Bild.ua',
    bildcontrol: ' — BildControl.ua',
    a2: ' — a2.kiev.ua',
  },

  redis: {
    host: process.env.REDIS_HOST,
    port: process.env.REDIS_PORT
  },

  assetsUrl: '/assets/',
  joke: false,
  baseSentryURL: process.env.BASE_SENTRY_URL,
  sentryPoint: process.env.SENTRY_POINT,
  application: {
    // daysHidden: 15,
    // daysReminder: 90,
    appName: 'Domik.ua',
    appDeveloper: 'Domik Incorporated.',
    appIdIOS: 123,
    appIdAndroid: 123
  },
  // Domains, that not transform into internal redirect link
  whitelistDomains: [
    'domik.ua',
    'forum.domik.ua',
    'bild.ua',
    'bildcontrol.ua',
    'a2.kiev.ua',
    process.env.DOMAIN_DOMIK,
    process.env.DOMAIN_BACKOFFICE,
    process.env.DOMAIN_FORUM,
    process.env.DOMAIN_BILD,
    process.env.DOMAIN_BILDCONTROL,
    process.env.DOMAIN_A2,
  ],

  // Query tags, that will be removed on server
  blacklistQueryParams: [
    'utm_source',
    'utm_term',
    'utm_campaign',
    'utm_medium',
    'utm_content',
    'fbclid',
    'gclid',
    '_ga',
    '_gac',
    '_gaexp',
    'test_remove'
  ],

  telegramlink: {
    url: '//t.me/joinchat/AAAAAEg5ZldD9rPy2p6O1Q'
  },
  fbDomik: {
    url: '//www.facebook.com/Domiknet'
  },
  yTDomik: {
    url: '//www.youtube.com/user/domiknet'
  },

  // adId: '0288393265238251', old AdSense ID
  adId: '2114088267444001',
  adDataDomik: [{
    slot: '6075920004',
    format: 'auto'
  }],
  adDataBild: [
    {
      slot: '5760633391',
      format: 'auto'
    }, {
      slot: '6075920004',
      format: 'link'
    }
  ],
  adDataForum: [
    {
      slot: '5760633391',
      format: 'auto'
    }, {
      slot: '6075920004',
      format: 'link'
    }
  ],

  // oauth: process.env.OAUTH,
  oauth: {
    fb: {
      url: process.env.OAUTH_FACEBOOK,
      window: [600, 400]
    },
    google: {
      url: process.env.OAUTH_GOOGLE,
      window: [600, 400]
    },
    /*
    vk: {
      url: '/service/socialAuth/login/vkontakte',
      window: [600, 400]
    },
    mailru: {
      url: '//www.mail.ru',
      window: [900, 650]
    },
    yandex: {
      url: '/service/socialAuth/login/yandex',
      window: [900, 650]
    },
    ok: {
      url: '//www.odnoklassniki.ru',
      window: [700, 400]
    },
    */
    tw: {
      url: process.env.OAUTH_TWITTER,
      window: [600, 400]
    },
    instagram: {
      url: process.env.OAUTH_INSTAGRAM,
      window: [600, 400]
    },
    linkedin: {
      url: process.env.OAUTH_LINKEDIN,
      window: [600, 400]
    },
    pinterest: {
      url: process.env.OAUTH_PINTEREST,
      window: [600, 400]
    }
  },
  apiKeys: {
    googleMaps: process.env.GOOGLE_MAPS_API_KEY,
    googleRecaptcha: process.env.GOOGLE_RECAPTCHA,
    visicomMaps: process.env.VISICOM_MAPS_KEY,
    fbDomikAppId: process.env.FACEBOOK_DOMIK_APP_ID,
    fbBildAppId: process.env.FACEBOOK_BILD_APP_ID
  }
};

module.exports.default = module.exports;
